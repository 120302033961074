import React from "react";

import Layout from "../../components/Layout";
import Hero from "./Hero";
import FeaturesHero from "./FeaturesHero";
import Features from "./Features";
import Languages from "./Languages";
import JoinCommunity from "./JoinCommunity";
import Footer from "./Footer";
import WebListsRow from "./WebListsRow";

// markup
const LandingContainer = ({ path }) => {
  return (
    <Layout contentContainerStyle={{ maxWidth: "100%", padding: 0 }} ogUrlPath="">
      <main>
        <Hero />
        <WebListsRow />
        <FeaturesHero />
        <Features />
        <Languages />
        <JoinCommunity />
        <Footer />
      </main>
    </Layout>
  );
};

export default LandingContainer;
