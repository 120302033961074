import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./Features.module.css";

const Features = () => {
  const features = [
    {
      title: "Learn",
      description: "Learn most common words and useful phrases with interactive flashcards. ",
      colorIcon: "#cc173c",
      colorBox: "#ffeef1",
      icon: <StaticImage src="../../../images/thinking.svg" alt="Learn" />,
    },
    {
      title: "Practice",
      description:
        "Various types of practice and review excercises help you double check the words you learn.",
      colorIcon: "#136ad5",
      colorBox: "#eff2fe",
      icon: <StaticImage src="../../../images/rocket-ship.svg" alt="Practice" />,
    },
    {
      title: "Games",
      description:
        "If you are advanced learner, the games offer you a unique and fun way to go through common words quickly.",
      colorIcon: "#0d6d2e",
      colorBox: "#e4ffed",
      icon: <StaticImage src="../../../images/puzzle.svg" alt="Games" />,
    },
  ];

  return (
    <div className={styles.container}>
      <h2>Why should I use Tobo?</h2>
      <div className={styles.featuresContainer}>
        {features.map((feature) => (
          <div key={feature.title} className={styles.itemContainer}>
            <div
              className={styles.itemIconIcontainer}
              style={{
                backgroundColor: feature.colorBox,
              }}
            >
              <div>{feature.icon}</div>
            </div>
            <h3
              style={{
                color: feature.colorIcon,
              }}
            >
              {feature.title}
            </h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
