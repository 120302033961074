// extracted by mini-css-extract-plugin
export var container = "Hero-module--container--dcd65";
export var content = "Hero-module--content--80d6b";
export var description = "Hero-module--description--c07f8";
export var downloadButtonsContainer = "Hero-module--downloadButtonsContainer--7b459";
export var heroContainer = "Hero-module--heroContainer--b62fa";
export var imageIphone = "Hero-module--imageIphone--5978f";
export var imageIphoneContainer = "Hero-module--imageIphoneContainer--7e871";
export var imageStar = "Hero-module--imageStar--e87d2";
export var reviewContainer = "Hero-module--reviewContainer--4cb89";
export var reviewCount = "Hero-module--reviewCount--06b58";
export var starsContainer = "Hero-module--starsContainer--82235";
export var test = "Hero-module--test--f3bd8";
export var title = "Hero-module--title--53da4";