import React, { useContext, useMemo } from "react";

import AppDownload from "../../../components/AppDownload";
import { ModalContext } from "../../../components/ModalProvider";
import {
  getAppStoreURLFromAppID,
  getGooglePlayURLFromAppID,
  getOS,
} from "../../../lib/helpers";
import * as Constants from "../../../lib/constants";
import * as styles from "./Languages.module.css";

const Languages = () => {
  const { openModal } = useContext(ModalContext);
  const platform = useMemo(() => getOS(), []);

  return (
    <div>
      <div
        style={{
          lineHeight: 0,
        }}
      >
        <svg
          viewBox="0 0 1440 120"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            width: "100%",
            height: "auto",
          }}
        >
          <path d="M1440 0v120H0z" fill="#f4f5fe" fillRule="nonzero" />
        </svg>
      </div>
      <div className={styles.container}>
        <h2>Which language do you want to learn?</h2>
        <div className={styles.languagesContainer}>
          {Constants.AVAILABLE_APPS.map((app) => (
            <a
              className={styles.langItemContainer}
              key={app.title}
              onClick={() => {
                let url;
                if (platform === "android" && app.androidID) {
                  url = getGooglePlayURLFromAppID(app.androidID);
                } else if (platform === "ios" && app.iosID) {
                  url = getAppStoreURLFromAppID(app.iosID);
                }

                if (url) {
                  window.open(url);
                } else {
                  openModal({
                    content: <AppDownload language={app.title} />,
                  });
                }
              }}
            >
              <div className={styles.langItemIcon}>
                <img
                  src={`/flags/${app.iconKey}.svg`}
                  alt={`${app.title} flag`}
                />
              </div>
              <span>{app.title}</span>
            </a>
          ))}
        </div>
      </div>
      <div
        style={{
          lineHeight: 0,
        }}
      >
        <svg
          viewBox="0 0 1440 120"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            width: "100%",
            height: "auto",
          }}
        >
          <path d="M0 120V0h1440z" fill="#f4f5fe" fillRule="nonzero" />
        </svg>
      </div>
    </div>
  );
};

export default Languages;
