import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { getGooglePlayURLFromAppID, getAppStoreURLFromAppID } from "../../../lib/helpers";

import * as Constants from "../../../lib/constants";

import * as styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.container}>
      <div className={styles.footerInner}>
        <div className={styles.appLinksContainer}>
          <div className={styles.appColumn}>
            <h3>Android Apps</h3>
            <ul>
              {Constants.AVAILABLE_APPS.filter((item) => !!item.androidID).map((item) => (
                <li key={item.title}>
                  <a href={getGooglePlayURLFromAppID(item.androidID)}>
                    Learn {item.title} Vocabulary Android App
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.appColumn}>
            <h3>iOS Apps</h3>
            <ul>
              {Constants.AVAILABLE_APPS.filter((item) => !!item.iosID).map((item) => (
                <li key={item.title}>
                  <a href={getAppStoreURLFromAppID(item.iosID)}>
                    Learn {item.title} Vocabulary iOS App
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.social}>
          <a rel="noreferrer" target="_blank" href={Constants.YOUTUBE_URL}>
            <StaticImage src="../../../images/youtube.png" alt="youtube" height={20} width={29} />
          </a>
          <a rel="noreferrer" target="_blank" href={Constants.FACEBOOK_URL}>
            <StaticImage src="../../../images/facebook.png" alt="facebook" height={20} width={20} />
          </a>
          <a rel="noreferrer" target="_blank" href={Constants.INSTAGRAM_URL}>
            <StaticImage
              src="../../../images/instagram.png"
              alt="instagram"
              height={20}
              width={20}
            />
          </a>
          <a rel="noreferrer" target="_blank" href={Constants.TWITTER_URL}>
            <StaticImage src="../../../images/twitter.png" alt="twitter" height={20} width={20} />
          </a>
        </div>
        <div>
          <Link to="/help-center" className={styles.termsLink}>
            Help Center
          </Link>
          <Link to="/courses" className={styles.termsLink}>
            Picture Dictionary (Beta)
          </Link>
        </div>
        <div className={styles.copyright}>
          <span>Tobo - {new Date().getFullYear()}</span>
          <span> | </span>
          <Link to="/terms" className={styles.termsLink}>
            Terms and Conditions
          </Link>
          <span> | </span>
          <Link to="/privacy" className={styles.termsLink}>
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
