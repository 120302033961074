import React, { useState } from "react";

import Button from "../../../components/Button";

import * as styles from "./JoinCommunity.module.css";

const validateEmail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const JoinCommunity = () => {
  const [email, setEmail] = useState("");

  return (
    <div className={styles.communityContainer}>
      <div className={styles.communityInnerContainer}>
        <h3 className={styles.communityTitle}>Join Tobo community now!</h3>
        <p className={styles.communityDescription}>
          Leave your email to learn about new features. We will not spam you. We're working on new
          features like word games, quizzes and more. We'd love to get your feedback as well.
        </p>
        <form
          action="https://formspree.io/wordsyapp@gmail.com"
          className={styles.communityForm}
          method="POST"
        >
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            className={styles.communityInput}
            placeholder="Your email address"
          />
          <Button label="Join" type="submit" name="join" disabled={!validateEmail(email)} />
        </form>
      </div>
    </div>
  );
};

export default JoinCommunity;
