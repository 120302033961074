import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./FeaturesHero.module.css";

const FeaturesHero = () => {
  const features = [
    {
      title: "Interactive Flashcards",
      description:
        "Swipe the card right if you learn, swipe left if you want to come back to the word later. Once you swipe right, it will be ready to practice and review.",
      image: (
        <StaticImage
          src="../../../images/feature1.png"
          alt="feature 1 app screenshot"
        />
      ),
    },
    {
      title: "Fun way of learning",
      description:
        "Practice the words you learn with various exercises. Play games if you feel confident already. Unlock phrases after reaching goals.",
      image: (
        <StaticImage
          src="../../../images/feature2.png"
          alt="feature 2 app screenshot"
        />
      ),
    },
    {
      title: "Most common 3500 words",
      description:
        "Common words take around 80% of daily conversations in many languages. Learn them fast.",
      image: (
        <StaticImage
          src="../../../images/feature2.png"
          alt="feature 2 app screenshot"
        />
      ),
    },
  ];
  return (
    <div className={styles.container}>
      {features.map((feature, index) => (
        <div
          className={`${styles.feature} ${
            index !== 1 ? styles.featureReverse : ""
          }`}
          key={feature.title}
        >
          <div className={styles.featureContent}>
            <div className={styles.contentInnerContainer}>
              <h2 className={styles.featureTitle}>{feature.title}</h2>
              <p className={styles.featureDescription}>{feature.description}</p>
            </div>
          </div>
          <div className={styles.featureImage}>{feature.image}</div>
        </div>
      ))}
    </div>
  );
};

export default FeaturesHero;
