import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./Hero.module.css";

import { getGooglePlayURLFromAppID, getAppStoreURLFromAppID } from "../../../lib/helpers";

import * as Constants from "../../../lib/constants";

const Hero = () => {
  let urlIOS = Constants.URL_DEVELOPER_PAGE_IOS;
  let urlAndroid = Constants.URL_DEVELOPER_PAGE_ANDROID;

  let params;
  let languageParam;

  if (typeof document !== "undefined") {
    params = new URLSearchParams(document.location.search.substring(1));
    languageParam = params.get(Constants.QUERY_PARAMATER_KEY_LANGUAGE);
  }

  let selectedApp;
  if (languageParam) {
    selectedApp = Constants.AVAILABLE_APPS.find((item) => item.key === languageParam);
  }

  if (selectedApp && selectedApp.iosID) {
    urlIOS = getAppStoreURLFromAppID(selectedApp.iosID);
  }

  if (selectedApp && selectedApp.androidID) {
    urlAndroid = getGooglePlayURLFromAppID(selectedApp.androidID);
  }
  return (
    <section className={styles.container}>
      <div className={styles.heroContainer}>
        <div className={styles.content}>
          <h1 className={styles.title}>Fun and easy way to learn most common words</h1>
          <p className={styles.description}>
            Learn new vocabulary every day quickly with{" "}
            <span className={styles.test}>fun games, flashcards, phrases</span> and more!
          </p>
          <div className={styles.reviewContainer}>
            <div className={styles.starsContainer}>
              {new Array(4).fill(0).map((_, index) => {
                return (
                  <StaticImage
                    className={styles.imageStar}
                    src="../../../images/star.svg"
                    alt="star"
                    key={index}
                  />
                );
              })}
              <StaticImage
                className={styles.imageStar}
                src="../../../images/star-clipped.svg"
                alt="star"
              />
              <div className={styles.reviewCount}>20K+ reviews</div>
            </div>
          </div>
          <div className={styles.downloadButtonsContainer}>
            <a href={urlIOS}>
              <img src="/download-app-store.svg" alt="Download Tobo IOS App" />
            </a>
            <a href={urlAndroid}>
              <img src="/download-google-play.svg" alt="Download Tobo Android App" />
            </a>
          </div>
        </div>
        <div className={styles.imageIphoneContainer}>
          <StaticImage className={styles.imageIphone} src={"../../../images/iphonex.png"} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
