import React from "react";
import { Link } from "gatsby";

import * as styles from "./WebListsRow.module.css";

const WebListsRow = () => {
  return (
    <Link to="/courses" className={styles.containerLink}>
      <div className={styles.innerContainer}>
        <div className={styles.textPictureDictionaryContainer}>
          <span>Check out Tobo Picture Dictionary</span>
        </div>
      </div>
    </Link>
  );
};

export default WebListsRow;
